import React from 'react';
import styled from 'styled-components';
import brickwall from '../assets/images/icons/icon-brickwall.png';

const ProjectsStyles = styled.div`
    text-align: center;
    img {
        width: 150px;
        margin: 72px auto;
        display: block;
    }
    p {
        font-size: 18px;
    }
`


export default function Projects() {
  return (
    <ProjectsStyles>
        <h2>Featured Projects</h2>
        <img src={brickwall} alt=""/>
        <p>Coming soon.</p>
        <a href="http://devgames.io">While here, please take a look at a few of my experiments in retro games using React</a>
    </ProjectsStyles>
  )
}