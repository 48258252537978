import React from 'react';
import Projects from '../components/Projects';


function ProjectsPage({}) {
    return (
        <Projects />
    )
}

export default ProjectsPage;